<script setup lang="ts">
import type { ReportType } from './types'
import { ForecastReportType } from '@reports/constants'

const props = withDefaults(
  defineProps<{ monthly: number; annual: number; disabled?: boolean }>(),
  {
    annual: 0,
    monthly: 0,
    disabled: false,
  },
)

const { createDivisionURL } = useDivisions()

const route = useRoute()
const reportTypes = computed<ReportType[]>(() => [
  {
    label: 'Leases',
    type: ForecastReportType.LEASES,
    to: {
      path: createDivisionURL(`/reports/view/forecast`),
      query: {
        leaseIds: route.query.leaseIds,
      },
    },
  },
  {
    label: 'Monthly view',
    number: props.monthly,
    type: ForecastReportType.MONTHLY,
    to: {
      path: createDivisionURL(`/reports/view/forecast/monthly`),
      query: {
        leaseIds: route.query.leaseIds,
      },
    },
    color: {
      number: 'text-green-500',
      background: 'bg-green-100 border-green-600',
      hover: 'hover:border-green-600',
    },
  },
  {
    label: 'Annual view',
    number: props.annual,
    type: ForecastReportType.ANNUAL,
    to: {
      path: createDivisionURL(`/reports/view/forecast/annual`),
      query: route.query,
    },
    color: {
      number: 'text-gray-500',
      background: 'bg-gray-100 border-gray-600',
      hover: 'hover:border-gray-600',
    },
  },
])
</script>

<template>
  <ButtonGroup>
    <ButtonGroupItem
      v-for="reportType in reportTypes"
      :key="reportType.type"
      :to="reportType.to"
      :exact="true"
      class="h-[38px]"
      :disabled="disabled && reportType.type !== ForecastReportType.LEASES"
    >
      <div class="flex items-center text-gray-100">
        <span>
          {{ reportType.label + (reportType.number ? ':' : '') }}
        </span>

        <span
          v-if="reportType.number"
          class="ml-1 text-lg font-bold"
          :class="reportType.color?.number"
        >
          {{ reportType.number }}
        </span>
      </div>
    </ButtonGroupItem>
  </ButtonGroup>
</template>
